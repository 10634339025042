'use client'

import { Message } from '@locmod/intl'
import clsx from 'clsx'
import { constants } from 'helpers'

import { buttonMessages } from 'components/inputs'
import { Href } from 'components/navigation'
import { Icon } from 'components/ui'
import { navMessages } from 'compositions/nav'


type Item = {
  title: Intl.Message | string
  to?: string
  toTab?: string
}

const columns: Item[][] = [
  [
    {
      title: navMessages.docs,
      toTab: constants.links.docs,
    },
    {
      title: navMessages.buildersHub,
      to: constants.links.buildersHub,
    },
    {
      title: navMessages.apps,
      to: '/ecosystem',
    },
    {
      title: navMessages.pools,
      toTab: constants.links.liquidity,
    },
    // {
    //   title: constants.azurToken.name,
    //   to: '/token',
    // },
  ],
  [
    {
      title: 'GitHub',
      toTab: constants.links.github,
    },
    {
      title: navMessages.audit,
      toTab: constants.links.audits,
    },
    {
      title: navMessages.privacy,
      toTab: constants.links.privacy,
    },
    {
      title: navMessages.terms,
      toTab: constants.links.terms,
    },
  ],
  [
    {
      title: navMessages.azuroscan,
      toTab: '',
    },
    {
      title: 'Twitter',
      toTab: constants.links.twitter,
    },
    {
      title: 'Discord',
      toTab: constants.links.discord,
    },
    {
      title: 'Blog',
      toTab: constants.links.medium,
    },
  ],
]

type NavProps = {
  className?: string
}

const Nav: React.FC<NavProps> = (props) => {
  const { className } = props

  return (
    <nav className={clsx(className, 'flex justify-between sm:justify-around items-start space-x-2 sm:space-x-7 text-sm sm:text-base text-left')} aria-label="Footer">
      {
        columns.map((items, index) => (
          <div key={index} className="w-fit space-y-6 sm:space-y-7">
            {
              items.map(({ title, to, toTab }) => (
                <Href
                  key={to || toTab}
                  className={
                    clsx(
                      'flex items-center text-azuro-sky whitespace-nowrap',
                      {
                        'hocus:text-white': to || toTab,
                        'cursor-default text-grey-60': !toTab,
                      }
                    )
                  }
                  to={to}
                  toTab={toTab}
                >
                  <Message value={title} html />
                  {
                    Boolean(typeof toTab !== 'undefined') && (
                      <Icon className="ml-0.5 sm:ml-1 size-3 sm:size-3.5 opacity-30" name="interface/extrernal-link" />
                    )
                  }
                </Href>
              ))
            }
          </div>
        ))
      }
    </nav>
  )
}

export default Nav
