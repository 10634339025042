'use client'

import Image, { type StaticImageData } from 'next/image'
// import { useMedia } from 'contexts'

import arringtonImage from 'public/images/backers/1-arrington-capital.png'
import delphiImage from 'public/images/backers/1-delphi-digital.png'
import etherealImage from 'public/images/backers/1-ethereal-ventures.png'
import opCryptoImage from 'public/images/backers/1-op-crypto.png'
import sevenxImage from 'public/images/backers/1-sevenx-ventures.png'
import allianceImage from 'public/images/backers/2-alliance.png'
import gnosisImage from 'public/images/backers/2-gnosis.png'
import meritCircleImage from 'public/images/backers/2-merit-circle.png'
import metaCartelImage from 'public/images/backers/2-meta-cartel.png'
import polymorphicImage from 'public/images/backers/2-polymorphic-capital.png'

// import { ScrollingBar } from 'components/dataDisplay'


type Item = {
  image: StaticImageData
  alt: string
  isDuplicate?: boolean
}

const row1Items: Item[] = [
  { image: delphiImage, alt: 'Delphi Digital' },
  { image: arringtonImage, alt: 'Arrington Capital' },
  { image: etherealImage, alt: 'Ethereal Ventures' },
  { image: sevenxImage, alt: 'SevenX Ventures' },
  { image: allianceImage, alt: 'Alliance' },
]

const row2Items: Item[] = [
  { image: polymorphicImage, alt: 'Polymorphic Capital' },
  { image: opCryptoImage, alt: 'OP Crypto' },
  { image: metaCartelImage, alt: 'MetaCartel' },
  { image: meritCircleImage, alt: 'Merit Circle' },
  { image: gnosisImage, alt: 'Gnosis Chain' },
]

// duplicate for animation
// row1Items.push(...row1Items.map((item) => ({ ...item, isDuplicate: true })))
// row2Items.push(...row2Items.map((item) => ({ ...item, isDuplicate: true })))


type BackersProps = {
  className?: string
}

const Backers: React.FC<BackersProps> = (props) => {
  const { className } = props

  // const { isSmView, isLgView } = useMedia()
  //
  // let roundDurationSec = 60
  //
  // if (isSmView) {
  //   roundDurationSec = 40
  // }
  // else if (isLgView) {
  //   roundDurationSec = 20
  // }

  return (
    <section className={className}>
      <h6 className="sr-only">Backed by</h6>
      {/*<ScrollingBar*/}
      {/*  contentClassName="opacity-75"*/}
      {/*  roundDurationSec={roundDurationSec}*/}
      {/*>*/}
      <div className="flex flex-wrap items-center justify-around -mx-2">
        {
          row1Items.map(({ image, alt }, index) => (
            <Image
              key={index}
              className="m-4 h-8 sm:h-14 w-auto max-w-none"
              src={image}
              alt={alt}
              title={alt}
              loading="lazy"
              draggable={false}
            />
          ))
        }
      </div>
      <div className="flex flex-wrap items-center justify-around -mx-2">
        {
          row2Items.map(({ image, alt }, index) => (
            <Image
              key={index}
              className="m-4 h-8 sm:h-14 w-auto max-w-none"
              src={image}
              alt={alt}
              title={alt}
              loading="lazy"
              draggable={false}
            />
          ))
        }
      </div>
      {/*</ScrollingBar>*/}
      {/*<ScrollingBar*/}
      {/*  className="mt-8 mask-gradient-to-t from-black/10 to-95% to-black/75"*/}
      {/*  roundDurationSec={roundDurationSec}*/}
      {/*  inverse*/}
      {/*>*/}
      {/*  <div className="flex flex-wrap items-center -mx-2">*/}
      {/*    {*/}
      {/*      row2Items.map(({ image, alt }, index) => (*/}
      {/*        <Image*/}
      {/*          key={index}*/}
      {/*          className="mx-2 h-8 w-auto max-w-none"*/}
      {/*          src={image}*/}
      {/*          alt={alt}*/}
      {/*          title={alt}*/}
      {/*          loading="lazy"*/}
      {/*          draggable={false}*/}
      {/*        />*/}
      {/*      ))*/}
      {/*    }*/}
      {/*  </div>*/}
      {/*</ScrollingBar>*/}
    </section>
  )
}

export default Backers
