import clsx from 'clsx'


type VideoOverlayProps = {
  className?: string
}

const VideoOverlay: React.FC<VideoOverlayProps> = (props) => {
  const { className } = props

  return (
    <div className={clsx(className, 'bg-brand-50')}>
      <video
        className="object-cover size-full"
        poster="/images/bg-gradient-cover.jpg"
        playsInline
        autoPlay
        muted
        loop
      >
        <source src="/video/bg-gradient.mp4" type="video/mp4" />
      </video>
    </div>
  )
}

export default VideoOverlay
